<template>
    <div class="page">
        <div style="background-color: #FFFFFF;">
            <div style="color: black;font-weight: bold;display: flex;"
                class="font-size16 main-title">
                创建/编辑长租套餐
            </div>
            <div class="border-radius4 main-back"
                style="background: #FFFFFF;display: flex;">
                <el-button type="primary" size="small" @click="goBack">返回</el-button>
            </div>
            <meta content="no-referrer" name="referrer" />
            <div class="content-box section">
                <easy-card title="车场信息">
                    <el-form ref="form" :model="model" :rules="rules" label-width="100px" size="small" label-position="left">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="套餐名称" prop="leaseName">
                                    <el-col :span="20">
                                        <el-input v-model="model.leaseName" placeholder="请输入套餐名称"></el-input>
                                    </el-col>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="选择车场" prop="parkingLotId">
                                    <el-col :span="18">
                                        <SuggestParking v-model="model.parkingLotId" style="width: 100%;" />
                                    </el-col>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="长租周期" prop="cycle">
                                    <el-col :span="20">
                                        <el-select v-model="model.cycle" placeholder="请选择长租周期" style="width: 100%;">
                                            <el-option label="月" value="0"></el-option>
                                            <el-option label="季度" value="1"></el-option>
                                            <el-option label="半年" value="2"></el-option>
                                            <el-option label="年" value="3"></el-option>
                                        </el-select>
                                    </el-col>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="有效时段" prop="effectiveType">
                                    <!-- <el-input v-model="model.address" placeholder="请输入详细地址"></el-input> -->
                                    <el-col :span="8">
                                        <el-select v-model="model.effectiveType" @change="changeEffect"
                                            placeholder="有效时段">
                                            <el-option label="全天" value="1"></el-option>
                                            <el-option label="分时段" value="0"></el-option>
                                        </el-select>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-time-select v-model="model.startTime" :disabled="model.effectiveType == 1"
                                            :picker-options="{
                                                start: '00:00',
                                                step: '00:15',
                                                end: '23:59'
                                            }" placeholder="开始时间">
                                        </el-time-select>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-time-select v-model="model.endTime" :disabled="model.effectiveType == 1"
                                            :picker-options="{
                                                start: '00:00',
                                                step: '00:15',
                                                end: '23:59'
                                            }" placeholder="结束时间">
                                        </el-time-select>
                                    </el-col>



                                    <!-- <el-time-picker is-range v-model="model.timeDate" @change="changeTime" range-separator="-" start-placeholder="开始时间"
                                    end-placeholder="结束时间" value-format="HH:mm" placeholder="选择时间范围">
                                </el-time-picker> -->
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </easy-card>

                <!-- <div class="separate-line"></div> -->
                <div class="content-box section">
                    <easy-card title="购买信息">
                        <el-form ref="form" style="padding: 0 30px;" :model="model" label-position="left" :rules="rules"
                            label-width="100px" size="small">

                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="支持购买方式" prop="buyWay">
                                        <el-col :span="20">
                                            <el-select v-model="model.buyWay" placeholder="请选择购买方式"
                                                style="width: 100%;">
                                                <el-option label="开放购买" value="0"></el-option>
                                                <el-option label="审核购买" value="1"></el-option>
                                                <el-option label="专享购买" value="2"></el-option>
                                                <el-option label="不开放购买" value="3"></el-option>
                                            </el-select>
                                        </el-col>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="线上展示" prop="isOnline">
                                        <el-col :span="20">
                                            <el-select v-model="model.isOnline" placeholder="请选择是否展示"
                                                style="width: 100%;">
                                                <el-option label="是" value="0"></el-option>
                                                <el-option label="否" value="1"></el-option>
                                            </el-select>
                                        </el-col>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="套餐价格" prop="price">
                                        <el-col :span="20">
                                            <el-input v-model="model.price" placeholder="请输入套餐价格"></el-input>
                                        </el-col>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="model.buyWay == 2">
                                    <el-form-item label="请输入车牌" prop="plate">
                                        <div style="display: flex;align-items: center;margin-bottom: 10px;"
                                            v-for="item, index in model.plate" :key="index">
                                            <el-select v-model="item.plateColor" placeholder="请选择车牌颜色" clearable>
                                                <el-option v-for="item in options" :key="item.value" :label="item.label"
                                                    :value="item.value">
                                                </el-option>
                                            </el-select>
                                            <el-input v-model="item.plateNumber" placeholder="请输入车牌号"
                                                style="width: 200px;" />
                                            <i class="el-icon-circle-plus-outline" style="font-size: 16px;"
                                                v-if="index == 0" @click="handlePlate"></i>
                                            <i class="el-icon-remove-outline" style="font-size: 16px;" v-else
                                                @click="handleRemovePlate(index)"></i>
                                        </div>
                                        <!-- <el-input v-model="model.plate" placeholder="请输入车牌"></el-input>
                                <div style="display: flex;align-items: center;">
                                    <i class="el-icon-warning-outline" style="color: #EA5504;margin-right: 5px;"></i>
                                    <span class="upload-tip">输入车牌号时请以逗号分隔</span>
                                </div> -->
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="套餐说明" prop="remark">
                                        <el-col :span="20">
                                            <el-input v-model="model.remark" placeholder="套餐说明"></el-input>
                                        </el-col>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-button class="xxd-button_save" type="primary" @click="save">保存
                            </el-button>
                        </el-form>
                    </easy-card>
                </div>
            </div>
            <SuccessDialog v-model="showDialog" @closeDialog="closeSuccDialog" :title="'操作成功'" :tip="successTip" />
        </div>
    </div>
</template>


<script>
import {
    getLeaseInfo,
    addLease,
    editLease
} from "@/api/lease";
import SuggestParking from "@/components/SuggestParking";
import SuccessDialog from "@/components/SuccessDialog";
import { Message } from "element-ui";

export default {
    data() {
        return {
            isLoading: false,
            dataList: [],
            ParkingLotId: '',
            orderDetatil: {}, //从上个页面获取到的订单信息
            orderInfoPlus: {},// 从接口获取的订单详情补充信息
            driveInPhotoList: [],
            driveOutPhotoList: [],
            inImgDataList: [], //路内停车驶入图片
            outImageDataList: [],//
            pictureDialogFormVisible: false, //查看图片弹窗
            businessDetailDialogFormVisible: false, //查看业务详情弹窗
            driveInTableData: [], //驶入记录
            driveOutTableData: [], //驶离记录
            payRecordTableData: [], //支付记录
            model: {
                leaseName: "",
                parkingLotId: "",
                cycle: "",
                price: "",
                plate: [{ plateNumber: "", plateColor: "" }],
                effectiveType: "1",
                startTime: "00:00",
                endTime: "23:59"
            },
            options: [
                {
                    value: "0",
                    label: "通用",
                    disabled: false,
                },
                {
                    value: "01",
                    label: "黄牌",
                    disabled: true,
                },
                {
                    value: "02",
                    label: "蓝牌",
                    disabled: true,
                },
                {
                    value: "06",
                    label: "黑牌",
                    disabled: true,
                },
                {
                    value: "03,04",
                    label: "新能源",
                    disabled: true,
                },
            ],
            rules: {
                leaseName: [{ required: true, message: "长租套餐名称不能为空" }],
                parkingLotId: [{ required: true, message: "车场不能为空" }],
                cycle: [{ required: true, message: "长租周期不能为空" }],
                price: [{ required: true, message: "价格不能为空" }],
                address: [{ required: true, message: "详细地址不能为空" }],
                parkingLocation: [{ required: true, message: "车场经纬度不能为空" }],
                parkingspaceNumber: [{ required: true, message: "泊位个数不能为空" }],
                parkingLotType: [{ required: true, message: "请选择停车场类型" }],
                // chargeType: [{required: true, message: "请选择充电类型"}],
                // isVisibleOnLine: [{required: true, message: "请选择是否可见"}],
                // settlementId: [{required: true, message: "请选择结算方"}],
                plandefaultAttachmentId: [
                    { required: true, message: "车场平面图不能为空" },
                ],
                sceneDefaultAttachmentId: [
                    { required: true, message: "车场实景图不能为空" },
                ],
            },
            showDialog: false,
            successTip: ""
        };
    },
    components: {
        SuggestParking,
        SuccessDialog
    },
    methods: {
        //返回停车订单页面
        goBack() {
            this.$router.back();
        },
        handlePlate() {
            this.model.plate.push({
                plateNumber: "",
                plateColor: ""
            })
        },
        closeSuccDialog(e) {
            console.log(e);
            this.showDialog = false;
        },
        handleRemovePlate(index) {
            this.model.plate.splice(index, 1)
        },
        //查询订单计费规则
        getCalcDetailsByParkId() {
            getLeaseInfo({ id: this.$route.query.id }).then(res => {
                if (res.code === 30 && res.result) {
                    console.log(res);
                    this.model = res.returnObject;
                    if (res.returnObject.plate.length == 0) {
                        this.model.plate = [{ plateNumber: "", plateColor: "" }]
                    }
                    if (res.returnObject.effectiveType != 0) {
                        this.model.effectiveType = "1";
                        this.model.startTime = res.returnObject.startTime || "00:00";
                        this.model.endTime = res.returnObject.endTime || "23:59";
                    }
                }
            })
        },
        changeTime(e) {
            this.model.startTime = e[0];
            this.model.endTime = e[1];
        },
        changeEffect(e) {
            if (e == 1) {
                this.model.startTime = "00:00";
                this.model.endTime = "23:59";
            }

        },
        save() {
            if (!this.model.plate.every(item => {
                return (item.plateColor && item.plateNumber) || (!item.plateColor && !item.plateNumber);
            })) {
                Message.info("车牌颜色跟车牌号需要同时填写");
                return;
            }
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (this.$route.query.id) {
                        editLease(this.model).then(res => {
                            if (res && res.code == 30) {
                                // Message.success("编辑成功");
                                this.showDialog = true;
                                this.successTip = "编辑长租套餐成功";
                            }
                        })
                    } else {
                        addLease(this.model).then(res => {
                            if (res && res.code == 30) {
                                // Message.success("新增成功");
                                this.showDialog = true;
                                this.successTip = "新增长租套餐成功";
                            }
                        })
                    }
                }
            })

        }
    },
    mounted() {
        if (this.$route.query.id) {
            this.getCalcDetailsByParkId();
        }
    },
};
</script>

<style lang="less" scoped>
.record-tilte {
    margin: 14px 0;
}

.span-margin {
    margin-left: 4px;
}

.showPicture {
    display: flex;
    flex-direction: row;
}

.pictureBox {
    border: 1px solid #bbb;
    width: 100%;
}

.boxF1 {
    margin-right: 10px;
}

.boxF2 {
    margin-left: 10px;
}

.carNumber {
    width: 100%;
    line-height: 30px;
    text-align: center;
    background: #0768FD;
    color: #fff;
}

.inOrOutTime {
    background: #f0f1f5;
    // padding-left: 15px;
    width: 100%;
    display: inline-block;
    line-height: 30px;
}

.photo-width {
    margin: 12px 8px;

    .photo-item {
        width: 100%;
        //display: flex;

        .top-img {
            //width: 284px;
            //height: 184px;
            margin-right: 20px;
            cursor: pointer;
        }
    }
}

.table-list {
    margin-top: 20px;
}

/deep/ .el-button--text {
    color: #0768FD !important;
}

.main-title {
    padding-left: 18px;
    padding-top: 18px;
    padding-bottom: 20px;
    border-bottom: 1px solid #FAFAFA;
}

.main-back {
    padding-left: 18px;
    margin-top: 20px;
    padding-bottom: 2px;
}
</style>